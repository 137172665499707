import { useState, useEffect } from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { BsInfoCircle } from 'react-icons/bs'

const PortfolioModal = ({ selectedItem, showModal, handleCloseModal, user }) => {
  // State to handle button disabled state and processing
  const [isProcessing, setIsProcessing] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [data, setData] = useState(null)

  // Function to handle the sale confirmation using fetch
  const handleConfirmSale = () => {
    setIsProcessing(true)

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/transactions/${selectedItem?.id}/sell`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.token}` // Assuming you have a token in user
        }
      }
    )
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`)
      }
      return response.json()
    })
    .then((data) => {
      setData(data) // Handle response data
      window.location.reload() // On success, reload the page
    })
    .catch((error) => {
      setErrorMessage(`Failed to process the sale: ${error.message}`)
      setIsProcessing(false) // Re-enable button after failure
    })
  }

  return (
    <>
     
      {selectedItem && (
        <div
          className={`modal fade ${showModal ? 'show' : ''}`}
          style={{ display: showModal ? 'block' : 'none' }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Sell Token</h5>
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-right">
                      You will lose 20% of your total investment due to market conditions or fees.
                      This amounts to approximately $
                      {Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }).format(selectedItem.total_amount * 0.2)}
                      .
                    </Tooltip>
                  }
                >
                  <span className="ms-2">
                    <BsInfoCircle style={{ cursor: 'pointer' }} />
                  </span>
                </OverlayTrigger>
                <button type="button" className="btn-close" onClick={handleCloseModal}></button>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to sell the token for {selectedItem.token.tokenname}?</p>
                <p>
                  <strong>Total Amount Invested:</strong> $
                  {Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(selectedItem.total_amount)}
                </p>

                <p>
                  <strong>Total Amount (including discount rate):</strong> $
                  {Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(selectedItem.total_amount * 0.8)}
                </p>

                {/* Show error message if it exists */}
                {errorMessage && <p className="text-danger">{errorMessage}</p>}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
                  Close
                </button>

                {/* Confirm Sale Button with API Call */}
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={isProcessing} // Disable button when processing
                  onClick={handleConfirmSale}
                >
                  {isProcessing ? 'Processing...' : 'Confirm Sale'}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PortfolioModal
