import React from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts'
import './InvestmentGrowthChart.css'

const InvestmentGrowthChart = () => {
  // Dados mockados para o crescimento mensal do investimento
  const data = [
    { month: 'Jan', value: 1000 },
    { month: 'Feb', value: 1200 },
    { month: 'Mar', value: 1500 },
    { month: 'Apr', value: 1800 },
    { month: 'May', value: 2000 },
    { month: 'Jun', value: 2300 },
    { month: 'Jul', value: 2500 },
    { month: 'Aug', value: 2800 },
    { month: 'Sep', value: 3000 },
    { month: 'Oct', value: 3200 },
    { month: 'Nov', value: 3500 },
    { month: 'Dec', value: 4000 }
  ]

  return (
    // <ResponsiveContainer width="30%" height={200}>
     <ResponsiveContainer className="responsivecontainer">
      <LineChart className='linechart' data={data} >
        {/* <LineChart className='linechart' data={data} > */}

        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis />
        <Tooltip />
        <Line type="monotone" dataKey="value" stroke="#8884d8" strokeWidth={2} />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default InvestmentGrowthChart
