import React, { useState, useEffect } from 'react'

import LPNavBar from '../components/LPNavBar'
import FooterComponent from '../components/Footer'
import MainText from '../components/Text-Main'
import CardLP from '../components/LP-cards'
import eduardo from '../assets/img/EduardoMaceira_lk.jpg'
import gustavo from '../assets/img/GustavoOliveira_lk.jpg'
import './AboutUs.css'
import linked from '../assets/img/linkedin-svgrepo-com.svg'
import logoKhiza from '../assets/img/LOGO_KHIZA-19_1.svg';
import logoxdc from '../assets/img/xdc-network-logo.svg'


function AboutUs() {
  const [primaryInvestments, setPrimaryInvestments] = useState([])
  const [cards, setCards] = useState([])

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/tokens?page=1&limit=10&isprimaryoffer=true`
    )
      .then((response) => response.json())
      .then((data) => {
        const investments = Object.keys(data).map((key) => data[key])
        setPrimaryInvestments(investments)
      })
      .catch((error) => console.error('Error fetching data:', error))

    fetch('/lpcards.json')
      .then((response) => response.json())
      .then((data) => setCards(data))
      .catch((error) => console.error('Error fetching cards data:', error))
  }, [])

  if (primaryInvestments.length === 0 || cards.length === 0) {
    return <div>Loading...</div>
  }

  return (
    <>
      <LPNavBar />
      <MainText />
      <div className='AboutUsMainText'>
        Speck Finance is a platform that facilitates the buying and selling of real-world asset
        (RWA) tokens. It aims to create a reliable and transparent trading environment for both
        accredited and regular investors by conducting thorough research and analysis on the tokens
        available for trade. This approach can help ensure that investors have access to quality
        assets while promoting trust and confidence in the trading process.
      </div>
      <hr className="my-4 border-grey" />
      <div>
        <div class="text-center mb-10">
          <h2 class="text-2xl font-semibold">Our Partners</h2>
        </div>
        <div className='partnerslogos'>
          <a href="https://khizadao.com/" target="_blank" rel="noopener noreferrer">
            <img
              src={logoKhiza}
              alt="Khiza Logo"
              className="aboutUsKhizalogo text-center"
            />
          </a>
          <a href="https://xinfin.org/" target="_blank" rel="noopener noreferrer">
            <img
              src={logoxdc}
              alt="XDC Logo"
              className="aboutUsKhizalogo text-center"
            />
          </a>
        </div>
      </div>
      <hr className="my-4 border-grey" />
      <div class="text-center mb-10">
        <h2 class="text-2xl font-semibold">Meet our leadership</h2>
      </div>
      <div class="tailwind ">
        <div class="text-center">
          <img
            alt="Eduardo Lombardi Maceira CEO"
            class="aboutusphoto"
            src={eduardo}
          />
          <h3 class="aboutusClevel ">Eduardo Lombardi Maceira</h3>
          <p class="text-gray-600">Founder &amp; CEO</p>
          <div class="aboutusClevel1 ">
            <a class="text-black" href="https://www.linkedin.com/in/eduardolombardimaceira/" target="_blank" rel="noopener noreferrer">
              <img
                src={linked}
                alt=""
                class="linkedinlogo"
              />
            </a>
          </div>
        </div>
        <div class="text-center">
          <img
            alt="Gustavo Antunes de Oliveira"
            class="aboutusphoto"
            src={gustavo}
          />
          <h3 class="aboutusClevel">Gustavo Antunes de Oliveira</h3>
          <p class="text-gray-600">Founder &amp; CTO</p>
          <div class="aboutusClevel1 ">
            <a class="text-black" href="https://www.linkedin.com/in/gustavo-oliveira-65366125/" target="_blank" rel="noopener noreferrer">
              <img
                src={linked}
                alt=""
                class="linkedinlogo"
              />
            </a>
          </div>
        </div>
      </div>
      <hr className="my-4 border-grey" />
      <div>
        <div class="text-center mb-10">
          <h2>Our Values</h2>
        </div>
        <div className="lpcard-container">
          {cards.map((card, index) => (
            <CardLP key={index} title={card['card-title']} text={card['card-text']} />
          ))}
        </div>
      </div>

      <FooterComponent />
    </>
  )
}

export default AboutUs
