import React, { useState, useEffect, useRef } from 'react'
import { useUser } from '../hooks/use-user.hook'

import { Container, Row, Col, Card, Button, Table } from 'react-bootstrap'

import SideBarc from '../components/SideBarc'
import LoginButton from '../components/LoginButton'
import GoBackButton from '../components/GoBackButton'
import { useApi } from '../hooks/use-api.hook'

import './Profile.css'
import { useNavigate } from 'react-router-dom'

const ProfilePage = () => {
  const navigate = useNavigate()
  const user = useUser()

  useEffect(() => {
    if (!user) {
      navigate('/login?re=profile')
    }
  }, [user, navigate])

  const { data, isLoading } = useApi(`/me`, `me`, Boolean(user))

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (!data) {
    return <div>Error loading data. Please try again later.</div>
  }

  // Get the latest approved KYC for full_name and date_of_birth
  const latestApprovedKYC = data.kycs.find((kyc) => kyc.approved)

  // Get KYC Status for the latest submission
  const latestKYC = data.kycs[data.kycs.length - 1] || {}
  const kycStatus =
    latestKYC.approved === null ? 'Pending' : latestKYC.approved ? 'Accepted' : 'Rejected'

  const addDaysToDate = (date, days) => {
    const result = new Date(date)
    result.setDate(result.getDate() + days)
    return result
  }

  return (
    <>
      <SideBarc />
      <div className="navbar1">
        <GoBackButton />
        <LoginButton />
      </div>
      <Container className="py-4 all">
        <h1>Profile Page</h1>
        <hr className="dividerrr" />
        <Card className="profile-card mb-4">
          <Card.Body>
            <Row>
              <Col md={6}>
                <h3>Personal Information</h3>
                <p>
                  <strong>Email:</strong> {data.user.email}
                </p>
                {latestApprovedKYC && (
                  <>
                    <p>
                      <strong>Full Name:</strong> {latestApprovedKYC.full_name}
                    </p>
                    <p>
                      <strong>Date of Birth:</strong> {latestApprovedKYC.date_of_birth}
                    </p>
                  </>
                )}
              </Col>
              <Col md={6}>
                <h3>KYC Status</h3>
                <p>
                  <strong>Latest Submission:</strong> {kycStatus}
                </p>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        {data.kycs.length === 0 ? (
          <Card className="profile-card">
            <Card.Body>
              <h3>No KYC Submissions Found</h3>
              <p>You have not submitted any KYC information yet.</p>
              <Button variant="primary" onClick={() => navigate('/kyc')}>
                Send KYC
              </Button>
            </Card.Body>
          </Card>
        ) : (
          <Card className="profile-card">
            <Card.Body>
              <h3>KYC Submissions</h3>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Full Name</th>
                    <th>Residential Address</th>
                    <th>Status</th>
                    <th>Rejection Reason</th>
                    <th>Approval Date</th>
                    <th>Created At</th>
                    <th>Valid Untill</th>
                  </tr>
                </thead>
                <tbody>
                  {data.kycs.map((kyc, index) => (
                    <tr key={kyc.id}>
                      <td>{index + 1}</td>
                      <td>{kyc.full_name}</td>
                      <td>{kyc.residential_address}</td>
                      <td>
                        {kyc.approved === null ? 'Pending' : kyc.approved ? 'Approved' : 'Rejected'}
                      </td>
                      <td>{kyc.rejection_reason || 'N/A'}</td>
                      <td>
                        {kyc.approval_date ? new Date(kyc.approval_date).toLocaleString() : 'N/A'}
                      </td>
                      <td>{new Date(kyc.created_at).toLocaleString()}</td>
                      <td>
                        {kyc.approval_date
                          ? addDaysToDate(kyc.approval_date, 365).toLocaleString()
                          : 'N/A'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Button variant="primary" onClick={() => navigate('/kyc')}>
                Submit New KYC
              </Button>
            </Card.Body>
          </Card>
        )}
      </Container>
    </>
  )
}

export default ProfilePage
