import React from 'react'
import SideBarc from '../components/SideBarc'
import { useNavigate, useParams } from 'react-router-dom'

const PurchaseConfirmation = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  function navigateClick() {
    navigate('/portfolio')
  }

  return (
    <>
      <SideBarc />
      <div
        className="d-flex justify-content-center"
        style={{ marginTop: '90px', textAlign: ' center' }}
      >
        <div>
          <p style={{ marginBottom: '250px' }}>Thank You For Your Purchase</p>
          <div>
            <p>Order #{id.toLocaleUpperCase()} Confirmed</p>
            <button
              class="btn btn-danger"
              style={{ width: '300px' }}
              onClick={() => navigateClick()}
            >
              Track Order
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default PurchaseConfirmation
