// Sidebar.js
import React from 'react'
import { Nav, OverlayTrigger, Tooltip } from 'react-bootstrap'
import './SideBarc.css' // Importar CSS para estilização personalizada
import logo from '../assets/img/mainLogo.svg' // Importar a imagem do logo
import homeIcon from '../assets/img/homebuttonsidebar.svg' // Importar ícone para Home
import configIcon from '../assets/img/configbutton.svg' // Importar ícone para Config
import portfolioIcon from '../assets/img/portifoliobutton.svg' // Importar ícone para Portfolio
import logout from '../assets/img/logout.svg' // Importar ícone para Logout
import approved from '../assets/img/approved.png' // Importar ícone para Aprovado
import pending from '../assets/img/pending.png' // Importar ícone para Pendente
import rejected from '../assets/img/rejected.png' // Importar ícone para Rejeitado
import { useUser } from '../hooks/use-user.hook'
import { Link } from 'react-router-dom'
import { useApi } from '../hooks/use-api.hook'

const SideBarc = () => {
  const user = useUser()
  const { data, isLoading } = useApi(user ? `/me` : null, 'me', Boolean(user))

  if (!user) {
    return (
      <div className="sidebar">
        {/* Logo */}
        <div className="sidebar-logo">
          <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-home">Home</Tooltip>}>
            <Link to="/home">
              <img src={logo} alt="Logo" className="logo-img sidebar-icon" />
            </Link>
          </OverlayTrigger>
        </div>
        {/* Links de navegação */}
        <Nav className="flex-column">
          <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-offers">Offers</Tooltip>}>
            <Nav.Link href="/offers">
              <img src={homeIcon} alt="Home" className="sidebar-icon" />
            </Nav.Link>
          </OverlayTrigger>
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip id="tooltip-portfolio">My Portfolio</Tooltip>}
          >
            <Nav.Link href="/portfolio">
              <img src={portfolioIcon} alt="Portfolio" className="sidebar-icon" />
            </Nav.Link>
          </OverlayTrigger>
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip id="tooltip-settings">Settings</Tooltip>}
          >
            <Nav.Link href="/profile" className="config-link">
              <div className="config">
                <img src={configIcon} alt="Config" className="sidebar-configicon" />
              </div>
            </Nav.Link>
          </OverlayTrigger>
        </Nav>
      </div>
    )
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (!data) {
    return <div>Error loading data. Please try again later.</div>
  }

  const latestKYC = data.kycs[data.kycs.length - 1] || {}
  const kycStatus = latestKYC.approved === null ? pending : latestKYC.approved ? approved : rejected

  return (
    <div className="sidebar">
      {/* Logo */}
      <div className="sidebar-logo">
        <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-home">Home</Tooltip>}>
          <Link to="/home">
            <img src={logo} alt="Logo" className="logo-img sidebar-icon" />
          </Link>
        </OverlayTrigger>
      </div>
      {/* Links de navegação */}
      <Nav className="flex-column">
        <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-offers">Offers</Tooltip>}>
          <Nav.Link href="/offers">
            <img src={homeIcon} alt="Home" className="sidebar-icon" />
          </Nav.Link>
        </OverlayTrigger>
        <OverlayTrigger
          placement="right"
          overlay={<Tooltip id="tooltip-portfolio">My Portfolio</Tooltip>}
        >
          <Nav.Link href="/portfolio">
            <img src={portfolioIcon} alt="Portfolio" className="sidebar-icon" />
          </Nav.Link>
        </OverlayTrigger>
        {user && (
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip id="tooltip-kyc">KYC information</Tooltip>}
          >
            <Nav.Link href="/kyc">
              <img src={kycStatus} alt="KYC_Information" className="sidebar-icon " />
            </Nav.Link>
          </OverlayTrigger>
        )}
        <Nav.Link href="/profile" className="config-link">
          {user && (
            <div className="profile">
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="tooltip-profile">Profile</Tooltip>}
              >
                <img
                  src={user?.picture}
                  alt={`${user?.given_name}'s profile`}
                  className="sidebar-configicon"
                />
              </OverlayTrigger>
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="tooltip-logout">logout</Tooltip>}
              >
                <div
                  className="logout"
                  onClick={() => {
                    if (user) {
                      sessionStorage.removeItem('sk_user')

                      window.location.href = '/home'
                    }
                  }}
                >
                  <img src={logout} alt="Portfolio" className="sidebar-icon" />
                </div>
              </OverlayTrigger>
            </div>
          )}
        </Nav.Link>
      </Nav>
    </div>
  )
}
export default SideBarc
