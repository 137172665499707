import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation from react-router-dom
import CardToken from "../components/Card-Tokens";
import SideBarc from "../components/SideBarc";
// import filterlines from "../assets/img/filterlines.svg"
import "./Offers.css";
import LoginButton from "../components/LoginButton";
import RedTag from '../components/RedTag';

function Offers() {
    const [primaryInvestments, setPrimaryInvestments] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const criteria = ["Government Bonds", "Private Equity", "Real Estate", "Commodities"];
    // const filterrrrr = [{filterlines}];
    const location = useLocation(); // Use useLocation to access URL parameters

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/tokens?page=1&limit=20`)
            .then((response) => response.json())
            .then((data) => {
                setPrimaryInvestments(
                    data.data.map((investment, index) => ({
                        ...investment,
                        id: investment.id
                    }))
                )
            })
            .catch((error) => console.error('Error fetching data:', error))
    }, []);
    // Parse the URL query parameters and set the initial filter
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const category = params.get('category');
        if (category && !selectedFilters.includes(category)) {
            setSelectedFilters([category]);
        }
    }, [location.search]);
    const addFilter = (event) => {
        const value = event.target.value;
        if (value && !selectedFilters.includes(value)) {
            setSelectedFilters([...selectedFilters, value]);
        }
    };
    const removeFilter = (filter) => {
        setSelectedFilters(selectedFilters.filter(f => f !== filter));
    };
    const filteredInvestments = selectedFilters.length > 0
        ? primaryInvestments.filter(investment => selectedFilters.includes(investment.assettype.toLowerCase().replace(' ', '-')))
        : primaryInvestments;
    if (primaryInvestments.length === 0) {
        return <div>Loading...</div>;
    }
    return (
        <>
            <SideBarc />
            <div className='Offerings1'>
                Offerings
                <RedTag />

                <LoginButton />
            </div>
            <div className="Filter-and-filterTag">
                <div className='Adjusting-filter-tag'>
                    {selectedFilters.map((filter, index) => (
                        <div key={index} className="filter-tag">
                            <span>{filter}</span>
                            <button onClick={() => removeFilter(filter)}>x</button>
                        </div>
                    ))}
                </div>
                <div>

                    <select onChange={addFilter} defaultValue="" className='SeletcFilterButton'>
                        <div>aaaa</div>
                        <option className="filterButton" value="" disabled> Filter </option>
                        {criteria.map((criterion, index) => (
                            <option key={index} value={criterion.toLowerCase().replace(' ', '-')}>{criterion}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="rowS">
                {/* <div className="col-md-1"></div> */}
                <div className="OfferCol">
                    <div className="card-container">
                        {filteredInvestments.map((investment, index) => (
                            <CardToken key={index} investment={investment} />
                        ))}
                    </div>
                </div>
                {/* <div className="col-md-3"></div> */}
            </div>
        </>
    );
}
export default Offers;