import React from 'react'
// import 'buy-tokens-button.css'
import './Buy-tokens-button.css'
import { useNavigate } from 'react-router-dom'

const BuyTokensButton = () => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/Offers')
  }

  return (
    <button className="pill-button" onClick={handleClick}>
      Buy Tokens
    </button>
  )
}

export default BuyTokensButton
