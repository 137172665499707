import React, { act, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import SideBarc from '../components/SideBarc'
import './Portifolio.css'
import { useApi } from '../hooks/use-api.hook'
import { useUser } from '../hooks/use-user.hook'
import 'bootstrap'
import { useRef, useState } from 'react'
import PortfolioModal from './PortifolioModal'
import bootstrapBundleMin from 'bootstrap/dist/js/bootstrap.bundle.min'
import ToastApi from '../components/ToastApi'
import InvestmentGrowthChart from '../components/InvestmentGrowthChart'

const Portifolio = () => {
  const navigate = useNavigate()
  const [isProcessing, setIsProcessing] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [toastActive, setToastActive] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [toastType, setToastType] = useState('')
  const user = useUser()

  const { data, isLoading } = useApi(
    '/transactions?page=1&limit=1000',
    'portifolio',
    Boolean(user?.token)
  )

  const [showModal, setShowModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  const handleOpenModal = (item) => {
    setSelectedItem(item)
    setShowModal(true)
  }

  const handleConfirmClaim = (item) => {
    setIsProcessing(true)
    setToastActive(false)
    fetch(`${process.env.REACT_APP_API_BASE_URL}/transactions/${item?.id}/claim`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user?.token}` // Assuming you have a token in user
      }
    })
      .then((response) => {
        if (!response.ok) {
          response.json().then((data) => {
            console.log(data)
            setToastMessage(data.error)
            setToastType('error') // Options: success, danger, warning, info
            setToastActive(true)
          })

          throw new Error(`Error: ${response.status}`)
        }
        return response.json()
      })
      .then((data) => {
        // setData(data) // Handle response data
        window.location.reload() // On success, reload the page
      })
      .catch((error) => {
        setErrorMessage(`Failed to process the sale: ${error.message}`)
        setIsProcessing(false) // Re-enable button after failure
      })
  }
  //////////////////////////////////
  const handleDelete = (item) => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/transactions/${item?.Id}`, {
      method: 'DELETE', // Use DELETE method for deletion
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user?.token}` // Assuming you have a token in user
      }
    })
      .then((response) => {
        if (!response.ok) {
          response.json().then((data) => {
            console.log(data)
            setToastMessage(data.error)
            setToastType('error') // Options: success, danger, warning, info
            setToastActive(true)
          })
          throw new Error(`Error: ${response.status}`)
        }
        // Optionally, you can refresh the data or remove the item from the state
        window.location.reload() // Reload the page on success
      })
      .catch((error) => {
        setErrorMessage(`Failed to delete the item: ${error.message}`)
      })
  }

  /////////////////////////////////
  const handleCloseModal = () => {
    setShowModal(false)
    setSelectedItem(null)
    console.log('close')
  }

  const STATUS = {
    CREATED: 'created',
    PROCESSING: 'processing',
    SUCCEEDED: 'succeeded',
    FAILED: 'failed',
    TO_SELL: 'to_sell',
    SOLD: 'sold',
    TO_CLAIM: 'to_claim',
    CLAIMED: 'claimed'
  }
  const getStatusText = (status) => {
    switch (status) {
      case STATUS.CREATED:
        return 'Awaiting payment'
      case STATUS.PROCESSING:
        return 'Processing'
      case STATUS.SUCCEEDED:
        return 'Succeeded'
      case STATUS.TO_SELL:
        return 'In sell process'
      case STATUS.SOLD:
        return 'Sold'
      case STATUS.CLAIMED:
        return 'Claimed'
      case STATUS.TO_CLAIM:
        return 'In claim process'
      case STATUS.FAILED:
        return 'Failed'
      default:
        return ''
    }
  }

  const shouldShowPayNowButton = (status) => status === STATUS.CREATED
  const sellButton = (status) => status === STATUS.SUCCEEDED
  const claimButton = (status) =>
    !shouldShowPayNowButton(status) &&
    status != STATUS.TO_CLAIM &&
    status != STATUS.PROCESSING &&
    status != STATUS.CLAIMED &&
    status != STATUS.TO_SELL &&
    status != STATUS.SOLD

  const claimDate = (date) => {
    const resultDate = new Date(date)
    resultDate.setDate(resultDate.getDate() + 2)
    return resultDate.toDateString()
  }
  const statusBadge = ({ item }) => {
    const statusText = getStatusText(item.status)
    console.log(item.status, statusText)
    return (
      <div>
        <div className="badgeAlingToTheRight">
          <span className={`badge status ${item.status.toLowerCase()}`}>
            <i className="bi bi-check-circle pr-8"></i>
            {statusText}
          </span>
          {shouldShowPayNowButton(item.status) && (
            <>
              <Link to={`/payments/${item.id}`} className="btn btn-primary badgeAlingToTheRight ">
                Pay Now
              </Link>
              <button
                onClick={() => handleDelete(item.id)}
                className="btn btn-danger badgeAlingToTheRight"
              >
                Delete
              </button>
            </>
          )}
          <div className="padding-buttons"></div>
          {sellButton(item.status) && item.generate_token != '' && (
            <span onClick={() => handleOpenModal(item)} className="btn btn-primary ds-block">
              Sell token
            </span>
          )}
          <div className="padding-buttons"></div>
          {claimButton(item.status) && (
            <span
              onClick={() => handleConfirmClaim(item)}
              className={`btn ${
                item.status === STATUS.SUCCEEDED &&
                new Date() > new Date(item.token.redemptiondeadline) &&
                item.generate_token != ''
                  ? 'btn-success'
                  : 'btn-secondary active'
              } ds-block`}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={`You can claim this investment after ${claimDate(item.token.redemptiondeadline)}`}
            >
              Claim
            </span>
          )}
          {toastActive && <ToastApi active={toastActive} message={toastMessage} type={toastType} />}
        </div>
      </div>
    )
  }

  const searchInputRef = useRef(null)

  const toggleSearchInput = (event) => {
    event.target.style.display = ' none'
    const searchInput = searchInputRef.current
    if (searchInput.style.display === 'none' || searchInput.style.display === '') {
      searchInput.style.display = 'block'
      searchInput.focus()
    } else {
      searchInput.style.display = 'none'
    }
  }

  useEffect(() => {
    if (!user) {
      navigate('/login?re=portfolio')
    }
  }, [user, navigate])
  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    tooltipTriggerList.forEach((tooltipTriggerEl) => {
      new bootstrapBundleMin.Tooltip(tooltipTriggerEl)
    })
  }, [])

  return (
    <>
      <SideBarc />
      <div className="container">
        <header className="d-flex justify-content-between align-items-center MyPortfoliHeader">
          <h3 className="mb-4">My portfolio</h3>
          <div>
            <span className="input-group" onClick={(e) => toggleSearchInput(e)}>
              <i className="bi bi-search"></i>
            </span>
            <div className="input-group" ref={searchInputRef} style={{ display: 'none' }}>
              <span className="input-group-text bg-transparent pb-0 pt-0">
                <i className="bi bi-search"></i>
                <input
                  type="text"
                  className="form-control search-input border-0 shadow-none"
                  placeholder="Search"
                />
              </span>
            </div>
          </div>
        </header>
        <div
          className="accordion"
          id="portfolioAccordion"
          data-bs-toggle="collapse"
          data-bs-target="#collapseOne"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          {}
        </div>

        {isLoading ?? <p>loading...</p>}

        {data && data.length > 0 ? (
          data.map((item) => (
            <>
              <div className="mb-3">
                <div className="EachProduct mb-4" key={item.id}>
                  <div className="PandingNextToTokenName">
                    <h5 className="ProdcutName">{item.token.tokenname}</h5>
                    {statusBadge({ item })}
                  </div>
                  {(item.status === 'succeeded' ||
                    item.status === 'to_sell' ||
                    item.status === 'to_claim' ||
                    item.status == 'sold') &&
                    item.generate_token !== '' && (
                      <div>
                        <p className="MyPortifolioinfo">
                          {' '}
                          <p className="text-muted info">
                            Token:{' '}
                            <b>
                              {' '}
                              <a
                                href={`https://xdcscan.com/tx/${item.generate_token}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {item.generate_token}
                              </a>
                            </b>
                          </p>
                        </p>
                      </div>
                    )}
                  {item.status === 'succeeded' && item.generate_token == '' && (
                    <p className="MyPortifolioinfo">
                      {' '}
                      <p className="text-muted info">
                        Token: <b>Generating</b>
                      </p>
                    </p>
                  )}
                  <div className="myPortifolioInformation01 ">
                    <section className="myPortifolioInformation02 ">
                      <p className="text-muted info">
                        Strategy <b>{item.token.strategy}</b>
                      </p>
                      <p className="text-muted info">
                        Domicile <b>{item.token.domicile}</b>
                      </p>
                      <p className="text-muted info">
                        Seller name <b>{item.token.sellername}</b>
                      </p>
                    </section>

                    <section className="myPortifolioInformation02 ">
                      <p className="text-muted info">
                        Share Price <b>${item.token.shareprice}</b>
                      </p>
                      <p className="text-muted info">
                        Date of Purchase <b>{item.created_at.split('T')[0]}</b>
                      </p>
                      <p className="text-muted info">
                        {' '}
                        Redemption deadline <b>{item.token.redemptiondeadline}</b>
                      </p>
                    </section>
                    <InvestmentGrowthChart className=" "></InvestmentGrowthChart>
                    <section className="myPortifolioInformation03 ">
                      <p className="MyPortifolioinfo">
                        {' '}
                        Total Amount Invested{' '}
                        <b>
                          {' '}
                          $
                          {Intl.NumberFormat('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }).format(item.total_amount)}{' '}
                        </b>
                      </p>
                    </section>
                  </div>
                </div>
              </div>
            </>
          ))
        ) : (
          <>
            <h1>Hello There {user?.given_name}</h1>
            <h3>This is Where you can see your portfolio once you have invested in some tokens</h3>
          </>
        )}
      </div>
      <PortfolioModal
        selectedItem={selectedItem}
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        user={user}
      />
    </>
  )
}

export default Portifolio
