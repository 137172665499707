import useSWR, { useSWRConfig } from 'swr'
import fetch from 'unfetch'
import { useUser } from './use-user.hook'
import { combineURLs } from '../utils/utils'

const useApi = (query, key, shouldFetch, auth = true) => {
  const baseUrl = `${process.env.REACT_APP_API_BASE_URL}`
  const url = combineURLs(baseUrl, query)

  const user = useUser()

  const { mutate } = useSWRConfig()
  const swrOptions = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  }

  const headers = {
    accept: 'application/json',
    'Content-Type': 'application/json'
  }

  if (user && auth) {
    headers['Authorization'] = `Bearer ${user.token}`
  }

  const fetcher = async () => {
    const response = await fetch(url, {
      headers: headers
    })

    if (!response.ok) {
      const error = new Error('An error occurred while fetching the data.')
      error.info = await response.json()
      error.status = response.status
      throw error
    }

    return await response.json()
  }

  const { data, error } = useSWR(shouldFetch ? key : null, fetcher, { ...swrOptions })

  return { data: data, isLoading: !error && !data, error, mutate }
}

export { useApi }
