import React, { useState, useEffect } from 'react'
import CardLP from '../components/LP-cards'
import './Home.css'
import FooterComponent from '../components/Footer'
import MainText from '../components/Text-Main'
import UnveilingOpportunites from '../components/UnveilingOpportunites'
import GetEarlyAcessButton from '../components/GetEarlyAcessButton'
import LPNavBar from '../components/LPNavBar'
import ModalLegalInformation from '../components/Modal-Legal-Information'

function LP() {
  const [primaryInvestments, setPrimaryInvestments] = useState([])
  const [cards, setCards] = useState([])

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/tokens?page=1&limit=10&isprimaryoffer=true`
    )
      .then((response) => response.json())
      .then((data) => {
        const investments = Object.keys(data).map((key) => data[key])
        setPrimaryInvestments(investments)
      })
      .catch((error) => console.error('Error fetching data:', error))

    fetch('/lpcards.json')
      .then((response) => response.json())
      .then((data) => setCards(data))
      .catch((error) => console.error('Error fetching cards data:', error))
  }, [])

  if (primaryInvestments.length === 0 || cards.length === 0) {
    return <div>Loading...</div>
  }

  return (
    <>
      <ModalLegalInformation />
      <LPNavBar />
      <MainText />

      <UnveilingOpportunites />

      {/* Aqui é onde os cards serão mostrados */}
      <div className="lpcard-container">
        {cards.map((card, index) => (
          <CardLP key={index} title={card['card-title']} text={card['card-text']} />
        ))}
      </div>
      {/* Até aqui! */}

      <div className="align">
        <div className="Maintext">Invest in the future with Speck Finance</div>
        <div className="align SecondaryText">
          Simplify your investment strategies, diversify your portfolio, and enjoy enhanced
          accessibility.
          <div></div>Click and start today to transform your financial potential!
        </div>
        <div className="addMargin">
          <GetEarlyAcessButton></GetEarlyAcessButton>
        </div>
      </div>
      <FooterComponent />
    </>
  )
}

export default LP
