import React, { useState, useEffect } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import CheckoutForm from '../components/CheckoutForm'
import './Payments.css'
import SideBarc from '../components/SideBarc'
import { useApi } from '../hooks/use-api.hook'
import { useUser } from '../hooks/use-user.hook'
import { useNavigate, useParams } from 'react-router-dom'

// Initialize Stripe with your publishable key
const stripePromise = loadStripe(
  'pk_test_mb2L9wFZF5aBCxe0Nv37urHo00qH29Icay'
)

const Payments = () => {
  const { id } = useParams() // Get the id parameter from the URL
  const navigate = useNavigate()
  const user = useUser()

  if (!user) {
    navigate('/login')
  }

  const { data, isLoading } = useApi(
    `/transactions/${id}`,
    `tokens-${id}`,
    Boolean(user && id)
  )

  const intentId = data && data.payment_intent_id

  const { data: intent, isLoading: isIntentLoading } = useApi(
    `/payments/${intentId}`,
    `tokens-${intentId}`,
    Boolean(user && intentId)
  )

  if (!data) {
    return <p>nothing to pay or transaction not found</p>
  }

  if (isLoading || isIntentLoading) {
    return <p>loading...</p>
  }

  return (
    <>
      <SideBarc />
      <div className="container">
        {intent.status === 'succeeded' ? (
          <p>nothing to pay or transaction not found</p>
        ) : (
          intent?.client_secret && (
            <Elements stripe={stripePromise} options={{ clientSecret: intent.client_secret }}>
              <CheckoutForm
                intentSecret={intent.client_secret}
                idToken={intentId}
                amount={data.total_amount}
              />
            </Elements>
          )
        )}
      </div>
    </>
  )
}

export default Payments
