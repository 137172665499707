import { GoogleLogin } from '@react-oauth/google'
import SpeckLogoName from '../assets/img/SpeckLogoName.svg'
import './Login.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'

const Login = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const query = searchParams.get('re')

  const handleLoginSuccess = (response) => {
    const decoded = jwtDecode(response.credential)
    const userInfo = {
      given_name: decoded.given_name,
      email: decoded.email,
      picture: decoded.picture,
      exp: decoded.exp,
      token: response.credential
    }
    sessionStorage.setItem('sk_user', JSON.stringify(userInfo))
    
    query ? navigate(`/${query}`) : navigate('/offers')
  }

  const handleLoginFailure = (error) => {
    console.log('Login failed:', error)
  }

  return (
    <>
      <div className="align1">
        <div>
          <img className="logo" src={SpeckLogoName} alt="SpeckLogoName" />
        </div>
        <div className="Maintext">Sign In account</div>
        <GoogleLogin onSuccess={handleLoginSuccess} onError={handleLoginFailure} />
      </div>
    </>
  )
}

export default Login
