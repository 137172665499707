import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Login from '../pages/Login'
import Home from '../pages/Home' // Importação da exportação padrão do Home
import FullOffer from '../pages/FullOffer'
import Offers from '../pages/Offers'
import Portifolio from '../pages/Portifolio'
import Payments from '../pages/Payments'
import PurchaseConfirmation from '../pages/PurchaseConfirmation'
import KYC from '../pages/KYC'
import LP from '../pages/LP'
import AboutUs from '../pages/AboutUs'
import PageNotFound from '../pages/PageNotFound'
import ProfilePage from '../pages/Profile'

export function RouteApp() {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<LP />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/FullOffer" element={<FullOffer />} />
        <Route path="/Offers" element={<Offers />} />
        <Route path="/FullOffer/:id" element={<FullOffer />} />
        <Route path="/Portfolio" element={<Portifolio />} />
        <Route path="/Payments/:id" element={<Payments />} />
        <Route path="/confirmation/:id" element={<PurchaseConfirmation />} />
        <Route path="/KYC" element={<KYC />} />
        <Route path="/Profile" element={<ProfilePage />} />
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  )
}
