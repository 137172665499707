import React, { useState } from "react";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

const ToastApi = ({ active, message = "Default message", type = "info" }) => {
    const [show, setShow] = useState(active);

    return (
        <ToastContainer
            className="p-3"
            position="middle-center"
            style={{ zIndex: 1 }}
        >
            <Toast
                onClose={() => setShow(false)}
                show={show}
                delay={4000}
                autohide
                bg={type} // Bootstrap's contextual background classes (e.g., success, danger)
            >
                <Toast.Header>
                    <strong className="me-auto">Error</strong>
                    <small>Just now</small>
                </Toast.Header>
                <Toast.Body>{message}</Toast.Body>
            </Toast>
        </ToastContainer>
    );
};

export default ToastApi;