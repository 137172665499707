import React, { useState, useEffect } from 'react'
import CardLP from '../components/LP-cards'
import { SpeckNavBar } from '../components/NavBar'
import './Home.css'
import FooterComponent from '../components/Footer'
import BuyTokensButton from '../components/Buy-tokens-button'
import MainText from '../components/Text-Main'
import PrimaryOfferings from '../components/Primary-offerings'
import UnveilingOpportunites from '../components/UnveilingOpportunites'
import InvestInTheFuture from '../components/InvestInTheFuture'
import Listedbytype from '../components/listedbytype' // Importe o novo componente
import CardToken from '../components/Card-Tokens'
import ModalLegalInformation from '../components/Modal-Legal-Information'
// import RedTag from '../components/RedTag'

function Home() {
  const [primaryInvestments, setPrimaryInvestments] = useState([])
  const [cards, setCards] = useState([])

  useEffect(() => {
    console.log('Fetching data...')
    console.log(process.env.REACT_APP_API_BASE_URL)

    fetch(`${process.env.REACT_APP_API_BASE_URL}/tokens?page=1&limit=10&isprimaryoffer=true`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        const investments = Object.keys(data.data).map((key) => data.data[key])
        setPrimaryInvestments(investments)
      })
      .catch((error) => console.error('Error fetching data:', error))

    fetch('/lpcards.json')
      .then((response) => response.json())
      .then((data) => setCards(data))
      .catch((error) => console.error('Error fetching cards data:', error))
  }, [])

  if (primaryInvestments.length === 0 || cards.length === 0) {
    return <div>Loading...</div>
  }

  return (
    <>
      <ModalLegalInformation />
      <SpeckNavBar />
      {/* <RedTag/> */}

      <MainText />

      <BuyTokensButton />
      <PrimaryOfferings />

      <div className="card-container">
        {primaryInvestments.map((investment, index) => (
          <CardToken key={index} investment={investment} />
        ))}
      </div>

      <div>
        <Listedbytype /> {/* Adicionado aqui */}
      </div>
      <UnveilingOpportunites />

      {/* Aqui é onde os cards serão mostrados */}
      <div className="lpcard-container">
        {cards.map((card, index) => (
          <CardLP key={index} title={card['card-title']} text={card['card-text']} />
        ))}
      </div>
      {/* Até aqui! */}

      <InvestInTheFuture>oi</InvestInTheFuture>
      <FooterComponent />
    </>
  )
}

export default Home
