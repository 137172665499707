import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import logo from '../assets/img/mainLogo.svg'

const MyVerticallyCenteredModal = (props) => {
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <img src={logo} alt="logo" /> speck<d className="Copyright">©</d>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Important Legal information</h4>
        <p>
          The information on this site is not intended as a solicitation to purchase financial
          products directly from us. Tokenized financial assets are offered to the public by Speck
          Finance. These assets have not been registered under the U.S. Securities Act of 1933 or
          with any U.S. state or securities regulatory authority. Therefore, they may not be
          offered, sold, or delivered within the U.S. to U.S. Persons and can only be offered to
          Non-U.S. Persons. This website may contain advertising materials. We strongly recommend
          that potential investors review the prospectus filed with the Liechtenstein Financial
          Market Authority (FMA) before making any investment decisions to fully understand the
          associated risks and rewards. Approval of the prospectus does not constitute an
          endorsement of the products by any regulatory authority.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>I understand</Button>
      </Modal.Footer>
    </Modal>
  )
}

const App = () => {
  const [modalShow, setModalShow] = React.useState(false)

  // useEffect to show modal on component mount
  useEffect(() => {
    setModalShow(true)
  }, [])

  return (
    <>
      <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  )
}

export default App
